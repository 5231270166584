
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  Prop,
  Watch,
  Vue,
} from 'vue-property-decorator';

import { logger } from 'logs-api-client';

import { namespace } from 'vuex-class';

export interface IAsyncProviderRequestOptions {
  forceRequest?: boolean;
}

const cache = namespace('requestCache');

@Component
export default class AsyncProvider extends Vue {
  @Prop({
    required: true,
    default: '',
    type: String,
  }) readonly url!: string;

  @Prop({
    required: false,
    type: Object,
    default: () => ({}),
  }) readonly params!: object;

  @Prop({
    required: false,
    type: Object,
    default: () => ({}),
  }) readonly headers!: object;

  @Prop({
    required: false,
    type: Array,
    default: () => [],
  }) readonly mocked!: [];

  @Prop({
    required: false,
    type: Boolean,
    default: true,
  }) readonly autoLoad!: boolean;

  @Prop({
    required: false,
    type: Boolean,
    default: false,
  }) readonly useCache!: boolean;

  @Prop({
    required: false,
    type: Function,
    default: null,
  }) readonly afterRequest!: (responseData: any) => any | void;

  public loading = true;

  public error = null;

  public data!: any;

  private get useMock(): boolean {
    return process.env.NODE_ENV !== 'production'
      && Array.isArray(this.mocked) && !!this.mocked.length;
  }

  private get hash() {
    const obj = { url: this.url, params: this.params, headers: this.headers };
    return JSON.stringify(obj);
  }

  @cache.State((state) => state.cache) cachedRequest: any;

  @cache.Mutation('add')
  public addToCache!: (options: object) => void

  @Watch('url')
  private changeUrl() {
    if (this.autoLoad) {
      this.requestData();
    }
  }

  @Watch('params', { deep: true })
  private changeParams() {
    this.requestData();
  }

  @Watch('headers', { deep: true })
  private changeHeaders() {
    this.requestData();
  }

  mounted() {
    if (this.autoLoad) {
      this.requestData();
    }
  }

  public async requestData(options?: IAsyncProviderRequestOptions): Promise<any> {
    await this.$nextTick();
    this.loading = true;
    this.error = null;
    this.data = [];

    try {
      this.data = await this.getFromCache(options && options.forceRequest)
        || await this.getFromMocked()
        || await this.getFromRequest();

      await this.updateCache();
      this.doAfterRequest();
    } catch (e) {
      this.error = e as any;
      this.$alert?.danger({
        title: `${this.$t('MESSAGES.ATTEMPTION')}`,
        message: `${this.$t('MESSAGES.ERROR_TRY_AGAIN')}`,
        duration: 5000,
      });
    } finally {
      this.loading = false;
    }
    return this.data;
  }

  private async getFromRequest(): Promise<any> {
    this.logRequestData('Requesting data to API', null);
    const config = { params: this.params, headers: this.headers as Record<string, string | number | boolean> };
    const { data } = await this.$http.get(`${this.url}`, config);
    return data;
  }

  private async getFromMocked(): Promise<any> {
    if (this.useMock) {
      this.logRequestData('Using mocked request', this.mocked);
      // eslint-disable-next-line no-promise-executor-return
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return this.mocked || [];
    }
    return null;
  }

  private getFromCache(forceRequest?: boolean): any {
    if (!forceRequest && this.useCache && this.cachedRequest[this.hash]) {
      const cached = this.cachedRequest[this.hash];
      this.logRequestData('Using cached request', cached);
      return cached;
    }
    return null;
  }

  private updateCache() {
    if (this.useCache && this.cachedRequest[this.hash] !== this.data) {
      this.addToCache({ key: this.hash, data: this.data });
    }
  }

  private doAfterRequest() {
    if (this.afterRequest && typeof this.afterRequest === 'function') {
      const result = this.afterRequest(this.data);
      if (result) {
        this.data = result;
      }
    }
  }

  private async logRequestData(message: string, data: any[] | null) {
    logger.info(message, {
      url: this.url,
      params: this.params,
      headers: this.headers,
      data,
    });
  }
}
