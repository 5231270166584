var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"field"},[_c('p',{staticClass:"control",class:{
      'is-loading': _vm.loading
    },attrs:{"id":`${_vm.id}-control`,"testid":`control-${_vm.id}`}},[_c('label',{class:{
        'has-text-danger': _vm.error
      },attrs:{"id":`${_vm.id}-label`,"testid":`label-${_vm.id}`,"for":`${_vm.id}-data`}},[_vm._v(" "+_vm._s(_vm.label)+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueCheckbox),expression:"valueCheckbox"}],staticClass:"checkradio",attrs:{"id":`${_vm.id}-data`,"testid":`input-${_vm.id}`,"name":_vm.id,"disabled":_vm.disabled || _vm.loading,"type":"checkbox"},domProps:{"value":_vm.value,"checked":Array.isArray(_vm.valueCheckbox)?_vm._i(_vm.valueCheckbox,_vm.value)>-1:(_vm.valueCheckbox)},on:{"change":function($event){var $$a=_vm.valueCheckbox,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=_vm.value,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.valueCheckbox=$$a.concat([$$v]))}else{$$i>-1&&(_vm.valueCheckbox=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.valueCheckbox=$$c}}}})])]),_c('p',{staticClass:"help",class:{
      'has-text-danger': _vm.error
    },attrs:{"id":`${_vm.id}-helper`,"test-id":`helper-${_vm.id}`}},[_vm._v(" "+_vm._s(_vm.helperText)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }