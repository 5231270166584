
import Component, { mixins } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
// @ts-expect-error:
import customerDataService from '@platform/customer-data-service';
import SelectLanguage from '@/components/form/SelectLanguage.vue';
import SessionMixin from '@/mixins/session';
import { IUser } from '@/types/user';

@Component({
  components: {
    SelectLanguage,
  },
})

export default class TabMyAccount extends mixins(SessionMixin) {
  @Prop({
    required: true,
    default: 'my-account',
    type: String,
  }) readonly activeTab!: string;

  @Prop({
    required: true,
    default: () => ({}),
    type: Object,
  }) readonly user!: IUser;

  private isLoading = false;

  public hasCopyToken = false;

  private showLanguageSelect = false;

  private languageSelected = '';

  public isBlocked = false;

  private get url() {
    return `${process.env.VUE_APP_USM_URL}/users`;
  }

  public get plan() {
    return this.mxSession_plan;
  }

  @Watch('languageSelected')
  private changeSelected() {
    if (this.languageSelected !== this.user.language) {
      this.updateLanguage();
      this.user.language = this.languageSelected;
    }
  }

  async created() {
    await Promise.all([this.selectVisibilityHandler(), this.fillSelectDefaultOption()]);
    await this.fetchPlanStatus();
  }

  private async fetchPlanStatus() {
    try {
      const context = await customerDataService.getContext();
      this.isBlocked = context.planStatus === 'BLOCKED';
    } catch (error) {
      console.error('Failed to fetch context:', error);
    }
  }

  private mapPlan(value: string): string {
    return ['INTERNAL', 'CHANNEL'].includes(value) ? 'ENTERPRISE' : value;
  }

  private fillSelectDefaultOption() {
    this.languageSelected = this.user.language;
  }

  private async selectVisibilityHandler() {
    this.showLanguageSelect = await this.$canIViewFeature({ name: 'language-select' });
  }

  private async updateLanguage(): Promise<void> {
    this.isLoading = true;
    try {
      await this.$http.patch(this.url, this.buildLanguageRequestData());
      this.$alert.success({
        title: `${this.$t('ACCOUNT.LANGUAGE_CHANGED')}`,
        message: `${this.$t('ACCOUNT.LANGUAGE_UPDATED_SUCCESS')}`,
      });
    } catch (error) {
      this.$alert.warning({
        title: `${this.$t('ACCOUNT.LANGUAGE_CHANGED')}`,
        message: `${this.$t('ACCOUNT.LANGUAGE_UPDATED_FAILED')}`,
        duration: 5000,
      });
    } finally {
      this.isLoading = false;
    }
  }

  private buildLanguageRequestData(): object {
    return { language: this.languageSelected };
  }
}
